import * as React from "react";
import Terms from "components/landing-page/site/Terms";
import { graphql } from 'gatsby'
// import ThemeProvider from "./ThemeProvider";
// markup
const TermsPage = ({}) => {
  return <Terms/>
};


export default TermsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;