import styled from "styled-components";
// import { FixedContainer as Container } from "components/UI/Layouts";
// import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import { Link } from "gatsby-plugin-react-i18next";

// export const StyledContainer = styled(Container).attrs((props) => ({
//   fixed: true,
// }))`
//   color: ${(props) => props.theme.colors.text.default};
//   font-size: ${(props) => props.theme.fonts.body.m.size};
//   line-height: 1.6;
//   margin-bottom:100px;

//   > * {
//       padding-bottom: 16px;

//       > * {
//         padding-bottom: 16px;
//       }

//       > ul {
//     > li {
//       margin-left: 40px;
//     }
//   }

//       > h1 {
//         font-weight: ${props=>(props.theme.fonts.weight.bold)};

//         font-size: ${props=>(props.theme.fonts.title.xl.size)};
//       }

//       > h2 {
//         font-size: ${props=>(props.theme.fonts.title.m.size)};
//       }
//   }


// `;

export const StyledContainer = styled(Container).attrs({
  fixed: true
})`
  padding-top: 64px;
  
  color: ${(props) => props.theme.colors.text.default};
  font-size: ${(props) => props.theme.fonts.body.m.size};
  line-height: 1.6;
  margin-bottom:100px;

  > * {
      padding-bottom: 16px;

      > * {
        padding-bottom: 16px;
      }

      > ul {
    > li {
      margin-left: 40px;
    }
  }

      > h1 {
        font-weight: ${props=>(props.theme.fonts.weight.bold)};

        font-size: ${props=>(props.theme.fonts.title.xl.size)};
      }

      > h2 {
        font-size: ${props=>(props.theme.fonts.title.m.size)};
      }
  }



`;




export const StyledContactUs = styled.div`
  font-weight: ${props=>(props.theme.fonts.weight.bold)};
`;


export const StyledLink = styled(Link)`
color: ${props=>(props.theme.colors.colorful.secondary)};
cursor: pointer;
:hover {
    text-decoration: underline;
}
`;
